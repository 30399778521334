//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-button {
  .p-progress-spinner {
    height: auto;
  }
}

.p-toolbar.p-component {
  border: $borderCrudToolbar;
  background: $backgroundCrudToolbar;
}

div.layout-sidebar {
  z-index: 10;
}

.p-fluid .p-selectbutton > .p-button {
  width: auto;
  flex: none;
}
