.p-selection-column {
  width: 3em;
}

.p-column-filter {
  width: 100%;
}

.input-full-width {
  & .p-column-filter {
    width: 100%;
  }
}

.p-datatable {
  .expander-column {
    width: 3em;
  }
}

// TODO Adicionar SCSS do tema e usar variaveis do tema
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody {
  tr.tr-success {
    background-color: #d4ecd5;
  }

  tr:not(.p-highlight).tr-success:hover {
    background-color: #b2ddb4;
  }

  tr.tr-danger {
    background-color: #fac9c9;
  }

  tr:not(.p-highlight).tr-danger:hover {
    background-color: #f69e9e;
  }
}

.p-column-width-6 {
  width: 6vw;
}

.overflow-auto {
  overflow: auto;
}
