.localizacao-page {
  height: $PAGE_MAX_HEIGHT;

  .croqui-lotes {
    height: 100%;

    .card {
      height: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
    }

    .croqui-map {
      overflow: auto;
      border: 1px solid $CROQUI_MATRIZ_BORDER_COLOR;

      .matriz {
        display: grid;
        width: max-content;
      }

      .croqui-item {
        min-width: 5em;
        max-width: 13em;
        min-height: 5em;
        max-height: 11em;
        padding: 0.5em 0;
        border: 1px dashed $CROQUI_MATRIZ_BORDER_COLOR;
        overflow: auto;

        .title {
          text-align: center;
          font-weight: 500;
          padding: 0 0.25em;
        }

        & > .element-icon {
          $ICON_SIZE: 1.25em;

          font-size: $ICON_SIZE;
          text-align: center;
          height: 100%;

          > i {
            position: relative;
            top: calc(50% - (#{$ICON_SIZE} / 2));
          }
        }

        &.bloco {
          background-color: $CROQUI_BLOCO_COLOR;
        }

        &.parede {
          background-color: $CROQUI_PAREDE_COLOR;

          > .element-icon {
            color: $CROQUI_MATRIZ_BORDER_COLOR;
          }
        }

        &.porta {
          background-color: $CROQUI_PORTA_COLOR;
        }

        .saldo {
          margin: 0;
          padding: 0.26em 0.5em;
          justify-content: space-between;
          display: flex;
          cursor: pointer;

          &:not(:last-child) {
            border-bottom: 1px solid $CROQUI_MATRIZ_BORDER_COLOR;
          }

          &:hover {
            background-color: #ffffff86;
          }
        }

        &.with-saldo {
          background-color: lighten($CROQUI_BLOCO_COLOR_CONTRASTED, 20%);
        }

        &.contrasted {
          background-color: $CROQUI_BLOCO_COLOR_CONTRASTED;
        }
      }
    }
  }
}
