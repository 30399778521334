
.card-with-value {
  .item-sumary {
    position: relative;
    padding: 5px;
  }

  .item-title {
    font-size: 20px;
  }

  .item-description {
    color: #707070;
    display: block;
    margin-top: 10px;
  }

  .item-value {
    background-color: #007be5;
    color: #fff;
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 20px;
    padding: 3px 9px;
    border-radius: 3px;
  }
}
