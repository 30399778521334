.dialog-md {
  width: 50vw;
}

.dialog-lg {
  width: 100%;
}

.dialog-min-md {
  min-width: 50vw;
}
