// 70px = layout-main.padding-top
// 2rem = layout-main.padding-bottom
$PAGE_MAX_HEIGHT: calc(100vh - 70px - 2rem);

// Croqui
$CROQUI_PORTA_COLOR: #ecf0f1;
$CROQUI_BLOCO_COLOR: #ffeaa7;
$CROQUI_BLOCO_COLOR_CONTRASTED: darken($CROQUI_BLOCO_COLOR, 30%);
$CROQUI_PAREDE_COLOR: $maskBgColor;
$CROQUI_MATRIZ_BORDER_COLOR: #b2bec3;
