@function grid_template($columns) {
  @return repeat(auto-fit, 100% / $columns);
}

.row-expanded {
  display: grid;

  @media screen and (min-width: $sm) and (max-width: $md) {
    grid-template-columns: grid_template(1);
  }

  @media screen and (min-width: $md) and (max-width: $xl) {
    grid-template-columns: grid_template(2);
  }

  @media screen and (min-width: $lg) {
    grid-template-columns: grid_template(3);
  }

  .row-item {
    grid-area: auto;
    width: fit-content;
    max-height: 5em;
    overflow: auto;
    margin: 0.25em 0.5em;
  }
}
