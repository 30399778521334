.loader {
  &.p-dialog {
    box-shadow: none;

    .p-dialog-content {
      background-color: transparent;
      overflow: hidden;
    }

    .p-dialog-header {
      display: none;
    }

    .spinner {
      width: 4em;
      height: 4em;
    }
  }
}
