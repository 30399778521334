$CELL_SIZE: 40px;

.croqui-sketch {
  overflow: scroll;

  .line {
    display: flex;

    div {
      min-width: 2.9em;
      max-width: 3em;
      min-height: 2.9em;
      max-height: 3em;
      border: 1px;
      border-color: $CROQUI_MATRIZ_BORDER_COLOR;
      border-style: dashed;
      text-align: center;
      line-height: $CELL_SIZE;
      font-weight: 900;
    }
  }

  .croqui-item {
    > i {
      font-size: $CELL_SIZE * 0.4;
    }

    &.parede {
      background-color: $CROQUI_PAREDE_COLOR;

      > i {
        color: $CROQUI_MATRIZ_BORDER_COLOR;
      }
    }

    &.bloco {
      background-color: $CROQUI_BLOCO_COLOR;
    }

    &.porta {
      background-color: $CROQUI_PORTA_COLOR;
    }
  }

  .matriz-info {
    font-weight: bold;
    background-color: $backgroundCrudToolbar;
  }
}
