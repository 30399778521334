.btn-localizar {
  max-width: 100px;
}

.cursor-pointer {
  cursor: pointer;
}

.saldos-gerais-panel > h1 > span > span.p-text-secondary {
  font-size: 17px;
}

.panel-saldos-lotes-sem-vinculos {
  max-width: 600px;
}
