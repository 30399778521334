#layout-topbar {
  position: fixed;
  height: 50px;
  padding: 1em 2em 0 2em;
  color: #fff;
  z-index: 10;
  right: 0;

  @include clearfix();
  @include linear-gradient-left($topbarLeftBgColor,$topbarRightBgColor);

  transition: left $transitionDuration;

  .layout-menu-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: $topbarItemColor;
    transition: color $transitionDuration;

    span {
      font-size: 1.5em;
    }

    &:hover {
      color: $topbarItemHoverColor;
    }
  }

  .layout-topbar-icons {
    float: right;
    display: block;
    animation-duration: 0.5s;

    button {
      position: relative;
      color: $topbarItemColor;
      margin-left: 20px;
      display: inline-block;
      text-decoration: none;
      transition: color $transitionDuration;

      &:hover {
        color: $topbarItemHoverColor;
      }

      &:focus {
        @include focused();
      }

      span {
        &.layout-topbar-icon {
          font-size: 1.5em;
        }

        &.layout-topbar-item-text {
          font-size: 20px;
          display: none;
        }

        &.layout-topbar-badge {
          position: absolute;
          font-size: 10px;
          right: -5px;
          top: -5px;
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          color: $topbarItemBadgeColor;
          background-color: $topbarItemBadgeBgColor;
          border-radius: 50%;
        }
      }
    }

    .layout-topbar-dropdown {
      padding: 0;
      position: relative;
      display: inline-block;
      top: -8px;
    }

    .layout-topbar-search {
      padding: 0;
      position: relative;
      display: inline-block;
      top: -4px;

      input {
        display: inline-block;
        border: 0 none;
        font-size: $fontSize;
        background: transparent;
        border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
        outline: 0 none;
        box-shadow: none;
        color: $topbarSearchInputColor;
        width: 100px;
        padding: 1px 20px 1px 1px;
        margin: 0;
        border-radius: 2px;

        &::-webkit-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }

        &::-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }

        &:-moz-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }

        &:-ms-input-placeholder {
          color: $topbarSearchInputColor;
          opacity: 0.7;
          transition: opacity $transitionDuration;
        }
      }

      .layout-topbar-search-icon {
        font-size: 18px;
        position: absolute;
        top: -1px;
        right: 0;
      }

      &:hover {
        input {
          border-bottom-color: $topbarItemHoverColor;
          &::-webkit-input-placeholder { opacity: 1; }
          &::-moz-placeholder { opacity: 1; }
          &:-moz-placeholder { opacity: 1; }
          &:-ms-input-placeholder { opacity: 1; }
        }

        .layout-topbar-search-icon {
          color: $topbarItemHoverColor;
        }
      }
    }
  }

  .spinner {
    width: 20px;
    height: 20px;
  }
}
