.auditoria {
  .new-value {
    background-color: rgba(46, 160, 67, 0.2);
  }

  .old-value {
    background-color: rgba(218, 54, 51, 0.2);
  }

  .column-version {
    width: 16%;
  }

  .column-acao {
    width: 15%;
  }

  .column-date {
    width: 25%;
  }

  .column-alteracao {
    overflow-x: auto;
  }
}
