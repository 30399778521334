.custom-steps-parent {
  .p-steps {
    .p-menuitem-link {
      flex-direction: column;
    }

    .p-steps-item {
      &:first-child {
        .p-menuitem-link,
        .p-steps-title {
          padding-left: 0;
        }
      }

      &:last-child {
        .p-menuitem-link,
        .p-steps-title {
          padding-right: 0;
        }
      }
    }
  }

  .step-actions {
    padding-bottom: 0.75em;
    margin-bottom: 0.75em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px lightgray solid;
  }
}
