.planejamento-lista {
  .acoes-column {
    width: 7em;
  }
}

.armazenamento-select {
  max-width: 100%;

  .armazenamento-info {
    border-radius: 0.25em;
    border: 1px solid black;
    padding: 0.25em;
    display: inline-block;

    span:not(:first-child) {
      border-left: 1px solid black;
    }
  }

  .croqui-sketch .croqui-item.contrasted {
    background-color: $CROQUI_BLOCO_COLOR_CONTRASTED;
  }
}
