@media screen and (max-width: $xl) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (min-width: $xl + 1px) and (max-width: $sm) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: $sm + 1px) and (max-width: $md) {
  .hidden-md {
    display: none !important;
  }
}

@media screen and (min-width: $lg) {
  .hidden-lg {
    display: none !important;
  }
}

.multi-select-hide-toggle-all {
  .p-multiselect-header {
    > .p-checkbox {
      display: none;
    }
  }
}
