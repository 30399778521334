.layout-profile {
  text-align: center;
  padding: 20px 0;

  .p-button-link {
    .p-button-label {
      flex: 0 1 auto;
    }

    &:hover {
      .p-button-label {
        text-decoration: none !important;
      }
    }
  }

  img {
    width: 56px;
    margin: 10px;
  }
  /* stylelint-disable no-descending-specificity */
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      button {
        width: 100%;
        padding: 1em;
        border: 0 none;
        border-radius: 0;
        cursor: pointer;
        transition: color $transitionDuration;

        &:hover {
          color: $menuitemHoverColor !important;
        }

        span {
          margin-left: 0.25em;
          vertical-align: middle;
        }

        i {
          vertical-align: middle;
        }
      }

      .dropdown-button {
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }

        .p-dropdown {
          flex-grow: 1;
          flex-basis: 0;
          padding: 0.3em;
          margin: 0;
          display: inline-flex;
          cursor: pointer;
          position: relative;
          min-width: 4rem;
          color: $menuitemDarkColor;
          background: transparent;
          border: 0 none;
          font-size: 1rem;
          font-weight: 500;
          text-align: left;

          &:not(.p-disabled).p-focus {
            box-shadow: none;
          }

          &:focus-visible {
            outline: none;
          }
        }

        i {
          color: $menuitemDarkColor;
          margin-left: 1.4em;
          margin-block: auto;
        }

        .p-dropdown-label,
        .p-placeholder,
        .p-dropdown-trigger-icon {
          color: $menuitemDarkColor;
        }

        &:hover {
          background: transparent;

          .p-dropdown-label,
          i,
          .p-dropdown-trigger-icon {
            color: $menuitemDarkHoverColor;
          }
        }
      }
    }

    &.layout-submenu-wrapper-enter,
    &.layout-submenu-wrapper-leave-to {
      max-height: 0;
    }

    &.layout-submenu-wrapper-enter-to,
    &.layout-submenu-wrapper-leave {
      max-height: 1000px;
    }

    &.layout-submenu-wrapper-leave-active {
      overflow: hidden;
      transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
    }

    &.layout-submenu-wrapper-enter-active {
      overflow: hidden;
      transition: max-height 1s ease-in-out;
    }
  }

  .layout-profile-link {
    cursor: pointer;
    margin-bottom: 0.75em;
    transition: color $transitionDuration;

    i {
      display: inline-block;
      font-size: 16px;
      vertical-align: middle;
      margin-left: 0.5em;
    }
  }
}

.layout-sidebar-dark {
  .layout-profile {
    ul {
      background-color: $menuitemDarkActiveBgColor;

      li {
        button {
          color: $menuitemDarkColor;

          &:hover {
            color: $menuitemDarkHoverColor !important;
          }
        }
      }
    }

    .layout-profile-link {
      color: $menuitemDarkColor;

      &:hover {
        color: $menuitemDarkHoverColor !important;

        span {
          text-decoration: none !important;
        }
      }
    }
  }
}

.layout-sidebar-light {
  .layout-profile {
    ul {
      background-color: $menuitemActiveBgColor;

      li {
        button {
          color: $menuitemColor;

          &:hover {
            color: $menuitemHoverColor !important;
          }
        }
      }
    }

    .layout-profile-link {
      color: $menuitemColor;

      &:hover {
        color: $menuitemHoverColor !important;

        span {
          text-decoration: none !important;
        }
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
