@mixin adjustHoverColor($cor) {
  background: lighten($cor, 5%);
}

@mixin adjustHoverOpacity($cor, $alpha) {
  background: rgba($cor, $alpha);
}

@mixin adjustFocusOpacity($cor, $alpha) {
  box-shadow: 0 0 0 1px rgba($cor, $alpha);
}

.p-component {
  &.p-button.p-button-secondary,
  .p-buttonset.p-button-secondary > .p-button,
  .p-splitbutton.p-button-secondary > .p-button,
  .p-fileupload-choose.p-button-secondary {
    color: $secondaryButtonTextColor;
    background: $secondaryButtonColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondarybutton:enabled:hover,
  .p-buttonset.p-button-secondary > .p-buttonbutton:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:hover,
  .p-fileupload-choose.p-button-secondarybutton:enabled:hover {
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;

    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondarybutton:enabled:focus,
  .p-buttonset.p-button-secondary > .p-buttonbutton:enabled:focus,
  .p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:focus,
  .p-fileupload-choose.p-button-secondarybutton:enabled:focus {
    @include adjustFocusOpacity($secondaryButtonColor, 0.5);
    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondarybutton:enabled:active,
  .p-buttonset.p-button-secondary > .p-buttonbutton:enabled:active,
  .p-splitbutton.p-button-secondary > .p-buttonbutton:enabled:active,
  .p-fileupload-choose.p-button-secondarybutton:enabled:active {
    background: $secondaryButtonColor;
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondary:not(button):not(.p-disabled):hover,
  .p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button:not(button):not(.p-disabled):hover,
  .p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):hover {
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;

    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondary:not(button):not(.p-disabled):focus,
  .p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):focus,
  .p-splitbutton.p-button-secondary > .p-button:not(button):not(.p-disabled):focus,
  .p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):focus {
    @include adjustFocusOpacity($secondaryButtonColor, 0.5);
    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondary:not(button):not(.p-disabled):active,
  .p-buttonset.p-button-secondary > .p-button:not(button):not(.p-disabled):active,
  .p-splitbutton.p-button-secondary > .p-button:not(button):not(.p-disabled):active,
  .p-fileupload-choose.p-button-secondary:not(button):not(.p-disabled):active {
    background: $secondaryButtonColor;
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondarya:enabled:hover,
  .p-buttonset.p-button-secondary > .p-buttona:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-buttona:enabled:hover,
  .p-fileupload-choose.p-button-secondarya:enabled:hover {
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;

    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondarya:enabled:focus,
  .p-buttonset.p-button-secondary > .p-buttona:enabled:focus,
  .p-splitbutton.p-button-secondary > .p-buttona:enabled:focus,
  .p-fileupload-choose.p-button-secondarya:enabled:focus {
    @include adjustFocusOpacity($secondaryButtonColor, 0.5);
    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondarya:enabled:active,
  .p-buttonset.p-button-secondary > .p-buttona:enabled:active,
  .p-splitbutton.p-button-secondary > .p-buttona:enabled:active,
  .p-fileupload-choose.p-button-secondarya:enabled:active {
    background: $secondaryButtonColor;
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondary:not(a):not(.p-disabled):hover,
  .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):hover,
  .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover {
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;

    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondary:not(a):not(.p-disabled):focus,
  .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):focus,
  .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):focus,
  .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):focus {
    @include adjustFocusOpacity($secondaryButtonColor, 0.5);
    @include adjustHoverColor($secondaryButtonColor);
  }

  &.p-button.p-button-secondary:not(a):not(.p-disabled):active,
  .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):active,
  .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):active,
  .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):active {
    background: $secondaryButtonColor;
    color: $secondaryButtonTextColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondary.p-button-outlined,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined,
  .p-fileupload-choose.p-button-secondary.p-button-outlined {
    background-color: transparent;
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondary.p-button-outlinedbutton:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:hover,
  .p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:hover {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-outlinedbutton:enabled:active,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlinedbutton:enabled:active,
  .p-fileupload-choose.p-button-secondary.p-button-outlinedbutton:enabled:active {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):hover,
  .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):hover {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(.p-disabled):active,
  .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(.p-disabled):active {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-outlineda:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlineda:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlineda:enabled:hover,
  .p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:hover {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-outlineda:enabled:active,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlineda:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlineda:enabled:active,
  .p-fileupload-choose.p-button-secondary.p-button-outlineda:enabled:active {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):hover,
  .p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):hover {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):active,
  .p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):active {
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-text,
  .p-buttonset.p-button-secondary > .p-button.p-button-text,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text,
  .p-fileupload-choose.p-button-secondary.p-button-text {
    background-color: transparent;
    color: $secondaryButtonColor;
    border: $secondaryButtonBorder;
  }

  &.p-button.p-button-secondary.p-button-textbutton:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-textbutton:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-textbutton:enabled:hover,
  .p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:hover {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-textbutton:enabled:active,
  .p-buttonset.p-button-secondary > .p-button.p-button-textbutton:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-textbutton:enabled:active,
  .p-fileupload-choose.p-button-secondary.p-button-textbutton:enabled:active {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):hover,
  .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):hover {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-text:not(button):not(.p-disabled):active,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(.p-disabled):active,
  .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(.p-disabled):active {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-texta:enabled:hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-texta:enabled:hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-texta:enabled:hover,
  .p-fileupload-choose.p-button-secondary.p-button-texta:enabled:hover {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-texta:enabled:active,
  .p-buttonset.p-button-secondary > .p-button.p-button-texta:enabled:active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-texta:enabled:active,
  .p-fileupload-choose.p-button-secondary.p-button-texta:enabled:active {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):hover,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):hover,
  .p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):hover {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.04);
  }

  &.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):active,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):active,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):active,
  .p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):active {
    border: $secondaryButtonBorder;
    color: $secondaryButtonColor;

    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }

  &.p-button.p-button-secondary.p-button-text:not(a):not(.p-disabled):focus,
  .p-button.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):focus,
  .p-buttonset.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):focus,
  .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):focus,
  .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(a):not(.p-disabled):focus,
  .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(a):not(.p-disabled):focus,
  .p-fileupload-choose.p-button-secondary.p-button-text:not(a):not(.p-disabled):focus,
  .p-fileupload-choose.p-button-secondary.p-button-outlined:not(a):not(.p-disabled):focus {
    @include adjustHoverOpacity($secondaryButtonColor, 0.16);
  }
}
