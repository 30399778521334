/* stylelint-disable no-descending-specificity */
@media (min-width: $sm + 1px) {
  .layout-wrapper {
    &.layout-overlay {
      #layout-topbar {
        left: 0;
      }

      .layout-main,
      .layout-footer {
        margin-left: 0;
      }

      &.layout-overlay-sidebar-active {
        .layout-sidebar {
          left: 0;
        }

        #layout-topbar {
          left: 250px;
        }
      }

      &.layout-mobile-sidebar-active {
        .layout-sidebar {
          left: 0;
        }

        #layout-topbar {
          left: 250px;
        }
      }
    }

    &.layout-static {
      #layout-topbar {
        left: 250px;
      }

      .layout-main,
      .layout-footer {
        margin-left: 250px;
      }

      &.layout-static-sidebar-inactive {
        #layout-topbar {
          left: 0;
        }

        .layout-main,
        .layout-footer {
          margin-left: 0;
        }

        .layout-sidebar {
          transition: left $transitionDuration;
          left: -250px;
        }
      }
    }
  }
}

@media (max-width: $sm) {
  .p-filter-column {
    display: none !important;
  }

  .layout-wrapper {
    #layout-topbar {
      left: 0;
    }

    .layout-main,
    .layout-footer {
      margin-left: 0;
    }

    .layout-main {
      padding: 0;
      padding-top: 50px;
    }

    .layout-sidebar {
      margin-top: 50px;
    }

    &.layout-static-sidebar-inactive {
      .layout-sidebar {
        transition: left $transitionDuration;
        left: -250px;
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}
/* stylelint-enable no-descending-specificity */
