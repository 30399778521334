.lote {
  @media screen and (min-width: $sm) and (max-width: $md) {
    .col-status {
      width: 15%;
    }

    .col-actions {
      width: 10%;
    }
  }

  @media screen and (min-width: $md) and (max-width: $xl) {
    .col-status {
      width: 10%;
    }

    .col-peso-emb {
      width: 5%;
    }

    .col-numero {
      width: 30%;
    }

    .col-quantidade {
      width: 11%;
    }

    .col-actions {
      width: 12%;
    }
  }

  @media screen and (min-width: $lg) {
    .col-status {
      width: 11%;
    }

    .col-numero {
      width: 15%;
    }

    .col-actions {
      width: 6%;
    }
  }
}
