/* Typography */
.card {
  background-color: #fff;
  padding: 1em;
  margin-bottom: 16px;
  border-radius: $borderRadius;

  &.card-w-title {
    padding-bottom: 2em;
  }

  h1 {
    border-bottom: 1px solid #d5d5d5;
    padding: 0.1em;
    font-size: 24px;
  }
}

.width-full {
  width: 100%;
}

.allow-escape-notation {
  white-space: pre-wrap;
}
