.croqui-page {
  .elements {
    margin: 0 0 10px;
    background: $backgroundCrudToolbar;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;

    .p-buttonset {
      padding: 5px 0 10px;
    }

    .p-button {
      justify-content: center;
    }
  }

  .croqui-edit {
    &.submitting {
      cursor: wait;

      &::after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
