.filters {
  .p-input-icon-left,
  .global-filter {
    width: 100%;
  }

  .p-float-label {
    width: 100%;
  }

  .field {
    .p-inputwrapper,
    .p-inputnumber {
      width: 100%;
    }

    .select-operator {
      max-width: 10ch;
    }
  }

  .actions-buttons {
    text-align: right;
  }
}
