.dashboard-item {
  &,
  .p-card-body,
  .p-card-content {
    height: 100%;
    width: 100%;
  }

  .p-card-body {
    overflow: auto;
  }

  .item-header {
    justify-content: space-between;
  }

  .spinner-container {
    text-align: center;
    width: 100%;
    height: 5em;
  }
}

.dashboard-toolbar {
  margin: 0 0.67rem;
}
